<script lang="ts" setup>
import dayjs from "dayjs";

const props = defineProps<{
	isAvailable?: boolean;
	finishedAt?: string;
}>();
defineEmits<{ (event: "click"): void }>();

const prepareImgUrl = useImage();
const { t } = useT();
const { durationLeft, durationExceeded, reset } = useCountdown({
	timestamp: props.finishedAt || "",
	formatToken: dayjs(props.finishedAt).diff(dayjs(), "d") >= 1 ? "DD[d ]HH[h ]mm[m ]" : "HH[h ]mm[m ]ss[s ]"
});
const isGuest = useIsGuest();

watch(
	() => props.finishedAt,
	() => {
		reset(props.finishedAt || "");
	}
);
</script>
<template>
	<div class="activity-season" @click="$emit('click')">
		<picture>
			<source
				:srcset="
					prepareImgUrl('/nuxt-img/races/season-mob.png', {
						format: 'avif',
						height: 476
					})
				"
				media="(max-width: 767px)"
			/>
			<NuxtImg class="image" src="/nuxt-img/races/season.png" format="avif" width="416" height="340" alt="season" />
		</picture>
		<ABadge variant="skew" background="var(--cairns)" autosize>
			<AText class="text-cannes" variant="topeka" :modifiers="['bold', 'uppercase']">
				{{ t("Hawaii Adventure") }}
			</AText>
		</ABadge>

		<div class="content">
			<div class="prize-pool">
				<AText class="label text-cannes" variant="topeka" :modifiers="['bold', 'uppercase']">
					{{ t("Complete & Earn") }}
				</AText>
				<MPrizeFund variant="entries" :iconSize="24" img="/nuxt-img/cards/entries.png">
					<AText variant="tanta" :modifiers="['semibold']">
						{{ numberFormat(100_000) }}
					</AText>
				</MPrizeFund>
			</div>
			<AText v-if="!isGuest && !durationExceeded" class="text-conakry">
				{{ t("Ends in") }}&nbsp;
				<b class="text-cannes">{{ durationLeft }}</b>
			</AText>
			<AButton variant="primary">
				<AText variant="toledo" :modifiers="['uppercase', 'semibold']">
					{{ isAvailable ? t("Dive into") : t("Get Your Pass") }}
				</AText>
				<NuxtIcon v-if="!isAvailable" name="20/ticket" filled />
			</AButton>
		</div>
	</div>
</template>
<style lang="scss" scoped>
.activity-season.item {
	padding-top: gutter(5.25);

	@include media-breakpoint-down(md) {
		padding: gutter(14) 0 gutter(1.5);
	}

	.content {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@include media-breakpoint-down(md) {
			align-items: center;
		}
	}

	.image {
		width: 208px;
		height: 170px;
		position: absolute;
		right: 0;
		top: 0;

		@include media-breakpoint-down(md) {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.badge {
		position: absolute;
		left: 0;
		top: 0;
	}

	button {
		min-width: 165px;
		height: 36px;
		width: max-content;
		gap: gutter(1.5);

		.nuxt-icon {
			display: inline-flex;
			font-size: 27px;

			:deep(svg) {
				margin-bottom: 0;
			}
		}

		@include media-breakpoint-down(md) {
			min-width: 135px;
			max-width: 100%;

			> span {
				font-size: 13px;
			}

			.nuxt-icon {
				font-size: 24px;
			}
		}
	}

	.prize-pool {
		--m-fund-entries-color: var(--cixi);
		display: flex;
		align-items: center;
		z-index: 1;
		gap: gutter(2);

		.prize {
			gap: gutter(0.5);
		}

		@include media-breakpoint-down(md) {
			flex-wrap: wrap;
			justify-content: center;
			gap: gutter(0.5);

			span.tanta {
				font-size: 16px;
			}

			&:deep(img) {
				width: 20px;
				height: 20px;
			}
		}

		.label {
			max-width: 55px;

			@include media-breakpoint-down(md) {
				max-width: none;
				width: 100%;
				text-align: center;
			}
		}
	}
}
</style>
